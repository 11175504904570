import { Button, Checkbox, Form, Paper, Spacer, TextInput, fieldPath, useEvent } from 'libs/uikit/lib';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { styled } from 'styled-components';
import * as z from 'zod';
import { appconfig } from '../appconfig';

const schema = z.object({
  username: z.string(),
  password: z.string(),
  rememberMe: z.boolean().optional().default(false),
});

const ssoschema = z.object({
  domain: z.string(),
});

export function Login() {
  const [params, _] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit = useEvent(async (data: z.infer<typeof schema>) => {
    const form = new FormData();
    form.append('username', data.username);
    form.append('password', data.password);
    form.append('rememberMe', String(data.rememberMe));

    const qs = new URLSearchParams({
      returnUrl: params.get('ReturnUrl')!,
    });

    const response = await fetch(`${appconfig.idp}/Account/Login?${qs.toString()}`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      body: form,
      redirect: 'manual',
    });

    if (response.headers.get('content-type')?.includes('application/json')) {
      const responseJson = await response.json();
      if (response.status === 401) {
        setErrorMessage(responseJson.message);
      } else {
        window.location.assign(responseJson.returnUrl);
      }
    }
  });

  const onSsoSubmit = useEvent(async (data: z.infer<typeof ssoschema>) => {
    const qs = new URLSearchParams({
      domain: data.domain,
      returnUrl: params.get('ReturnUrl')!,
    });

    const response = await fetch(`${appconfig.idp}/Account/Login/External?${qs.toString()}`);
    const responseJson = await response.json();
    if (response.status === 404) {
      setErrorMessage(responseJson.message);
    } else {
      window.location.assign(responseJson.challenge);
    }
  });

  return (
    <FullScreen>
      <CenterCard elevation={5}>
        <Spacer justify="center" gap={0}>
          <img src={require('../assets/img/logo-vert.png')} width={200} />

          {errorMessage && <Paper elevation={0}>{errorMessage}</Paper>}

          <Form schema={schema} onSubmit={onSubmit}>
            <TextInput name={fieldPath(schema, 'username')} placeholder="Username" />
            <TextInput name={fieldPath(schema, 'password')} placeholder="Password" type="password" />
            <Checkbox name={fieldPath(schema, 'rememberMe')} label="remember me" />
            <Button type="submit" intent="primary" fullWidth>
              Login
            </Button>
          </Form>
        </Spacer>
      </CenterCard>
    </FullScreen>
  );
}

const FullScreen = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  justify-items: center;
  align-items: center;
`;

const CenterCard = styled(Paper)`
  border-radius: 15px;
  padding: 2rem;
`;
