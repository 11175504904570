import { UIKitFonts } from 'libs/uikit/fonts/UIKitFonts';
import { UIProvider } from 'libs/uikit/lib';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Login } from './pages/Login';

const root = createRoot(document.getElementById('root')!);

root.render(
  <React.StrictMode>
    <UIKitFonts />
    <UIProvider>
      <Router>
        <Routes>
          <Route path="/account/login" element={<Login />} />
        </Routes>
      </Router>
    </UIProvider>
  </React.StrictMode>
);
